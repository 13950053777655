<template>
  <section>
    <div class="main-contents-wrapper">
      <p class="dashtext">금일 손익 금액, 금일 베팅 건수, 금일 베팅 유저 수, 시간별 손익 현황 등의 통계 데이터는<br />마지막 접속 시각을 기준으로 1시간 뒤에 새롭게 업데이트하여 반영됩니다.</p>

      <div class="dashwrap">
         <ul class="dashth">
            <li>
               <em class="bgrd"><img src="@/assets/img/icon_cost.svg" /></em>
               <ul class="dashlist">
                  <li>
                     <span>{{ $t('table.head.inAmt') }} </span>
                     <span>￦ 225,662,364</span>
                  </li>
                  <li>
                     <span>전날 입금액</span>
                     <span>￦ 13,456,789</span>
                  </li>
                  <li>
                     <span>차액</span>
                     <span>￦ 13,456,789</span>
                  </li>
               </ul>
            </li>
            <li>
               <em class="bgrd"><img src="@/assets/img/icon_cost.svg" /></em>
               <ul class="dashlist">
                  <li>
                     <span>{{ $t('table.head.outAmt') }}</span>
                     <span>￦ 225,662,364</span>
                  </li>
                  <li>
                     <span>전날 출금액</span>
                     <span>￦ 13,456,789</span>
                  </li>
                  <li>
                     <span>차액</span>
                     <span>￦ 13,456,789</span>
                  </li>
               </ul>
            </li>
            <li>
               <em class="bgrd"><img src="@/assets/img/icon_cost.svg" /></em>
               <ul class="dashlist">
                  <li>
                     <span>{{ $t('table.head.profit') }}</span>
                     <span>￦ 225,662,364</span>
                  </li>
                  <li>
                     <span>전날 수익</span>
                     <span>￦ 13,456,789</span>
                  </li>
                  <li>
                     <span>차액</span>
                     <span>￦ 13,456,789</span>
                  </li>
               </ul>
            </li>
         </ul>
         <ul class="dashth">
            <li>
               <em class="bgyw"><img src="@/assets/img/icon_cost.svg" /></em>
               <ul class="dashlist">
                  <li>
                     <span>{{ $t('table.head.betAmt') }}</span>
                     <span>￦ 225,662,364</span>
                  </li>
                  <li>
                     <span>전날 베팅금</span>
                     <span>￦ 13,456,789</span>
                  </li>
                  <li>
                     <span>차액</span>
                     <span>￦ 13,456,789</span>
                  </li>
               </ul>
            </li>
            <li>
               <em class="bgyw"><img src="@/assets/img/icon_cost.svg" /></em>
               <ul class="dashlist">
                  <li>
                     <span>{{ $t('table.head.winAmt') }}</span>
                     <span>￦ 225,662,364</span>
                  </li>
                  <li>
                     <span>전날 당첨금</span>
                     <span>￦ 13,456,789</span>
                  </li>
                  <li>
                     <span>차액</span>
                     <span>￦ 13,456,789</span>
                  </li>
               </ul>
            </li>
            <li>
               <em class="bgyw"><img src="@/assets/img/icon_cost.svg" /></em>
               <ul class="dashlist">
                  <li>
                     <span>{{ $t('table.head.wl') }}</span>
                     <span>￦ 225,662,364</span>
                  </li>
                  <li>
                     <span>전날 윈루즈</span>
                     <span>￦ 13,456,789</span>
                  </li>
                  <li>
                     <span>차액</span>
                     <span>￦ 13,456,789</span>
                  </li>
               </ul>
            </li>
         </ul>
         <ul class="dashth">
            <li>
               <em class="bggn"><img src="@/assets/img/icon_nuser.svg" /></em>
               <ul class="dashlist">
                  <li>
                     <span>신규 가입자 수</span>
                     <span>1 명</span>
                  </li>
                  <li>
                     <span>전날 가입자 수</span>
                     <span>3 명</span>
                  </li>
               </ul>
            </li>
            <li>
               <em class="bggn"><img src="@/assets/img/icon_bet.svg" /></em>
               <ul class="dashlist">
                  <li>
                     <span>신규 베팅 건 수</span>
                     <span>1 건</span>
                  </li>
                  <li>
                     <span>전날 베팅 건 수</span>
                     <span>3 건</span>
                  </li>
               </ul>
            </li>
         </ul>
         <ul class="dashth">
            <li>
               <em class="bgbl"><img src="@/assets/img/icon_insure.svg" /></em>
               <a class="open"><img src="@/assets/img/icon_open.svg" /></a>
               <ul class="dashlist">
                  <li>
                     <span>{{ $t("common.credit") }}</span>
                     <span>{{ numberWithCommas(headCashInfo.creditAmt) }}</span>
                  </li>
                  <li>
                     <span>POINT</span>
                     <span>{{ numberWithCommas(headCashInfo.creditSubPoint) }}</span>
                  </li>
                  <li class="btnwrap">
                     <a @click="modalOpen('apply')">알 신청</a>
                     <a @click="modalOpen('exchange')">알 환전</a>
                  </li>
               </ul>
            </li>
            <li>
               <em class="bgbl"><img src="@/assets/img/icon_insure.svg" /></em>
               <a class="open"><img src="@/assets/img/icon_open.svg" /></a>
               <ul class="dashlist">
                  <li>
                     <span>보험</span>
                     <span>{{ numberWithCommas(headCashInfo.insuredAmt) }}</span>
                  </li>
                  <li>
                     <span>POINT</span>
                     <span>{{ numberWithCommas(headCashInfo.insuredSubPoint) }}</span>
                  </li>
                  <li class="btnwrap">
                     <a @click="modalOpen2('apply')">보험금 신청</a>
                     <a @click="modalOpen2('exchange')">보험금 환전</a>
                  </li>
               </ul>
            </li>
         </ul>
      </div>
    </div>
  </section>
</template>

<script>
import SearchFilter from "@/components/common/SearchFilter";
import DateSelector from "@/components/common/DateSelector";
import TableHead from "@/components/main/table/Head.vue";
import UiCheck from "@/components/ui/UiCheckSet";
import subTitle from "@/components/main/PageSubTitle";
import { betList, getCode } from "@/api/member.js";
import { GAME_INFO_LIST, GAME_CODE_NAME } from "@/libs/constants";
import { replaceDateT, numberWithCommas, getDateStr } from "@/libs/utils.js";
import Pagination from "@/components/common/Pagination";

export default {
  name: "BetList",
  components: {
    SearchFilter,
    DateSelector,
    TableHead,
    UiCheck,
    subTitle,
    Pagination
  },
  data: function () {
    return {
      tableData: {},
      headInfo: {
        fstColumn: false,
        dataList: ["index", "memId", "betType", "gameType", "gameIdx", "gameUniqueId", "betDate", "bfMoney", "betAmt", "winAmt", "wl", "afMoney", "status"]
      },
      reqData: {
        page: 1,
        count_per_list: "30",
        betType: "kgon",
        gameType: "1",
        memId: ""
      },
      pageInfo: {},
      betList: [],
      codeList: [],
      convertData: {},
      gameType: {},
      tabList: {},
      currentPage: "",
      dateConfigs: {
        enableTime: true,
        dateFormat: "Y-m-d H:i"
      },
      startDefault: "",
      endDefault: "",
      currentPageName: ''
    };
  },
  methods: {
    async setTabList() {
      const group = this.$route.meta.group;
      // console.log(group);
      const gameList = await this.$store.getters.gameList;
      // console.log(gameList);
      if (gameList.length) {
        const gameObj = gameList.find(obj => {
          return obj.code === group;
        });
        this.tabList = gameObj.child;
      }
    },
    async setGameType() {
      const pathStr = this.$route.path.split("/");
      this.reqData.vendorCode = pathStr[4]
      this.currentPage = pathStr[4];
    },
    pageSeach() {
      this.lastDataRefector(this.currentPage, 1);
    },
    setTitleName () {
      this.tabList.forEach(item => {
        if(this.currentPage.toString() == item.code) {
          this.currentPageName = item.codeName
        } else {
          if (this.currentPage == 'all' ) {
            this.currentPageName = '전체보기'
          }
        }
      })

      this.emitter.emit('gameTitle', this.currentPageName)
    },
    async changeListType(evt) {
      const target = evt.target;
      const chooseType = target.value;
      this.currentPage = chooseType;


      this.setTitleName()
      history.pushState(null, null, `${chooseType}`);
      this.lastDataRefector(chooseType, 1);
    },
    setStartDate(date) {
      // console.log(date);
      let _date = "";
      if (!date) {
        _date = new Date();
        _date.setHours(0, 0, 0);
      } else {
        _date = date[0];
      }
      this.startDefault = _date;
      this.reqData.startDate = getDateStr(_date, "yyyy-MM-dd HH:mm:ss");
    },
    setEndDate(date) {
      // console.log(date[0]);
      let _date = "";
      if (!date) {
        _date = new Date();
        _date.setHours(23, 59, 59);
      } else {
        _date = date[0];
      }
      this.endDefault = _date;
      this.reqData.endDate = getDateStr(_date, "yyyy-MM-dd HH:mm:ss");
    },
    async getBetList(code, pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum;
      }
      this.reqData.gameCategory = "casino";
      if (code === "all") {
        code = "";
      }
      this.reqData.vendorCode = code;
      const data = this.reqData;
      return await betList(data);
    },
    async getGameCode(reqData) {
      const data = reqData;
      return await getCode(data);
    },
    gameCodeDetacher(list) {
      const set = new Set(list);
      const uniqueType = [...set];
      return uniqueType;
    },
    async gameCodeConverter(list) {
      const convertList = {};
      for (const item of list) {
        convertList[item] = [];
        const data = await this.getGameCode(item);
        convertList[item] = data;
      }
      return convertList;
    },
    goPage(pageNum) {
      const code = this.reqData.vendorCode;
      this.lastDataRefector(code, pageNum);
    },
    async lastDataRefector(code, pageNum) {
      console.log('lastDataRefector')
      window.scrollTo(0, 0);
      if (!pageNum) {
        pageNum = 1;
      }
      const listRes = await this.getBetList(code, pageNum);
      const pageInfo = listRes.data.pageInfo;
      if (pageInfo) {
        this.pageInfo = pageInfo;
      }
      const list = listRes.data.list;
      console.log(list)
      const preBetType = [];
      list.forEach(item => {
        const status = item.betStatus;

        preBetType.push(item.betType);

        item.wlAmt = '0'
        const _wlAmt = item.betWinAmt - item.betAmt;
        const type = item.type
        if (type === 'win') {
          item.result = "승리";
          item.wl = true;
        } else if (type === 'lose') {
          item.result = "패배";
          item.wl = false;
        } else if(type === 'draw') {
          item.result = "무";
          item.wl = false;
        } else if(type === 'wait') {
          item.result = "대기";
          item.wl = false;
        } else {
          item.result = "취소";
          item.wl = false;
        }
        item.wlAmt = numberWithCommas(_wlAmt);
      });
      const gTypes = this.gameCodeDetacher(preBetType);
      this.convertData = await this.gameCodeConverter(gTypes);
      // console.log(this.convertData)
      list.forEach(item => {
        const gameCode = this.convertData[item.betType];
        for (const _item of gameCode) {
          if (_item.code === item.gameType) {
            item.provider = _item.codeName;
            item.gameType = _item.groupCode;
          }
        }
        item.gameType = GAME_CODE_NAME[item.gameType];
      });
      this.betList = list;
    }
  },
  watch: {
    gameList: {
      handler(value) {
        this.setTabList();
      }
    },
    tabList: {
      handler(value) {
        if(this.tabList.length>0 && this.currentPage) {
          this.setTitleName()
        }
      }
    }
  },
  computed: {
    gameList() {
      return this.$store.getters.gameList;
    }
  },
  async created() {
    if(this.$route.query.id){
      this.reqData.memId = this.$route.query.id;
    }
    this.setStartDate();
    this.setEndDate();
    await this.setTabList();
    await this.setGameType();
    this.lastDataRefector(this.reqData.vendorCode, 1);

    console.log(this.currentPage, this.tabList)
  }
};
</script>

<style scoped>
.dashtext {font-size: 12px;margin-bottom: 29px;line-height: 1.5em;}
.dashwrap {}
.dashth {display: flex;gap: 10px;margin-bottom: 15px;}
.dashth em {width: 39px;height: 43px;display: flex;align-items: center;justify-content: center;}
.dashth em img {height: 26px;}
.dashth > li {width: 279px;height: 140px;border-radius: 5px;border: 1px solid transparent;padding: 0 50px;position: relative;background: #000}
.dashth .open {position: absolute;right: 15px;top: 15px;cursor: pointer;}
.dashlist {margin-top: 15px;}
.dashlist li:first-child {margin-top: 0;margin-bottom: 8px;font-size: 16px;color: #fff;}
.dashlist li {display: flex;justify-content: space-between;margin-top: 5px;font-size: 12px;color: #b0b1bc;}
.bgrd {background: url('~@/assets/img/bg_rd.svg');}
.bgyw {background: url('~@/assets/img/bg_yw.svg');}
.bggn {background: url('~@/assets/img/bg_gn.svg');}
.bgbl {background: url('~@/assets/img/bg_bl.svg');}
.dashlist .btnwrap {display: flex;gap: 13px;margin-top: 8px;}
.btnwrap a {width: 100%;height: 25px;display: flex;align-items: center;justify-content: center;border-radius: 3px;border: 1px solid #fff;font-size: 13px;cursor: pointer;}
</style>
